module.exports.shelfLocation = [
  { x: -46, y: 10, z: 428 },
  { x: -46, y: 10, z: 322 },
  { x: -46, y: 10, z: 216 },
  { x: -46, y: 10, z: 104 },
  { x: -46, y: 10, z: 0 },
  { x: -46, y: 10, z: -104 },
  { x: -46, y: 10, z: -216 },
  { x: -46, y: 10, z: -322 },
  { x: -46, y: 10, z: -428 },
  { x: 72, y: 10, z: 428 },
  { x: 72, y: 10, z: 322 },
  { x: 72, y: 10, z: 216 },
  { x: 72, y: 10, z: 104 },
  { x: 72, y: 10, z: 0 },
  { x: 72, y: 10, z: -104 },
  { x: 72, y: 10, z: -216 },
  { x: 72, y: 10, z: -322 },
  { x: 72, y: 10, z: -428 },
  { x: -46, y: 176, z: 428 },
  { x: -46, y: 176, z: 322 },
  { x: -46, y: 176, z: 216 },
  { x: -46, y: 176, z: 104 },
  { x: -46, y: 176, z: 0 },
  { x: -46, y: 176, z: -104 },
  { x: -46, y: 176, z: -216 },
  { x: -46, y: 176, z: -322 },
  { x: -46, y: 176, z: -428 },
  { x: 72, y: 176, z: 428 },
  { x: 72, y: 176, z: 322 },
  { x: 72, y: 176, z: 216 },
  { x: 72, y: 176, z: 104 },
  { x: 72, y: 176, z: 0 },
  { x: 72, y: 176, z: -104 },
  { x: 72, y: 176, z: -216 },
  { x: 72, y: 176, z: -322 },
  { x: 72, y: 176, z: -428 },
  { x: -46, y: 326, z: 428 },
  { x: -46, y: 326, z: 322 },
  { x: -46, y: 326, z: 216 },
  { x: -46, y: 326, z: 104 },
  { x: -46, y: 326, z: 0 },
  { x: -46, y: 326, z: -104 },
  { x: -46, y: 326, z: -216 },
  { x: -46, y: 326, z: -322 },
  { x: -46, y: 326, z: -428 },
  { x: 72, y: 326, z: 428 },
  { x: 72, y: 326, z: 322 },
  { x: 72, y: 326, z: 216 },
  { x: 72, y: 326, z: 104 },
  { x: 72, y: 326, z: 0 },
  { x: 72, y: 326, z: -104 },
  { x: 72, y: 326, z: -216 },
  { x: 72, y: 326, z: -322 },
  { x: 72, y: 326, z: -428 },
  { x: -46, y: 477, z: 428 },
  { x: -46, y: 477, z: 322 },
  { x: -46, y: 477, z: 216 },
  { x: -46, y: 477, z: 104 },
  { x: -46, y: 477, z: 0 },
  { x: -46, y: 477, z: -104 },
  { x: -46, y: 477, z: -216 },
  { x: -46, y: 477, z: -322 },
  { x: -46, y: 477, z: -428 },
  { x: 72, y: 477, z: 428 },
  { x: 72, y: 477, z: 322 },
  { x: 72, y: 477, z: 216 },
  { x: 72, y: 477, z: 104 },
  { x: 72, y: 477, z: 0 },
  { x: 72, y: 477, z: -104 },
  { x: 72, y: 477, z: -216 },
  { x: 72, y: 477, z: -322 },
  { x: 72, y: 477, z: -428 },
];
